"use client";

import { useLayoutEffect } from "react";

export const Redirect = () => {
  useLayoutEffect(() => {
    // Redirect to the homepage with error code and URL
    const url = new URL(
      window.location.protocol + "//" + window.location.host + "/",
    );
    url.searchParams.append("err", "404");
    url.searchParams.append("err_url", window.location.href);
    document.documentElement.style.display = "none";
    window.location.replace(url);
  }, []);

  return (
    <noscript>
      {/**
       * TODO: Not sure how to get the current pathname on the server (vs client w/ noscript)
       * I thought that maybe we could get the current URL using the ESI plugin, but there is
       * transformation for non-200 response codes so we can't do that. However, I'm not sure
       * how much we really even care about these noscript browsers anyway so maybe it's fine
       * https://github.com/apache/trafficserver/blob/4244856b8ee3d6b4a72c6a8d572d9f4ac9cf8dfd/plugins/esi/esi.cc#L1234-L1235
       *
       * note that &nojs=1 is added to isolate the traffic evaluation for future use cases.
       */}
      <meta
        httpEquiv="refresh"
        content={`0;URL=https://creators.yahoo.com?err=404&nojs=1`}
      />
    </noscript>
  );
};
